.bold {
    font-weight: bold;
}

.helperText {
    font-size: 14px;
}

.footer {
    position: absolute;
    bottom: 10px;
}

.button {
    padding: 0px;
    color: #077398;
    text-decoration: underline;
    text-decoration-color: #077398;
}